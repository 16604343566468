import React from "react";
import PropertyService from "../../Services/Property";
import { Tooltip } from "@material-ui/core";

const Numbers = ({ property }) => {
    return (
        <table>
            <tr>
                <td class="pr-2">
                    <Tooltip title="Classe Energética" placement="top">
                        <img
                            title={property.energyclass}
                            src={process.env.PUBLIC_URL + "/static/react/property/energyclass/" + property.energyclass.replace(" ", "") + ".png"}
                            alt={"Classe Energética" + property.energyclass.replace(" ", "")}
                        />
                    </Tooltip>
                </td>
                {
                    property.areaground>0 && property.areaground!==property.area &&
                    <td class="px-2">
                        <Tooltip title="Área do terreno" placement="top">
                            <img
                                className="mx-3"
                                src={process.env.PUBLIC_URL + "/static/react/property/icons/areaterreno.png"}
                                alt="Área do terreno"
                            />
                        </Tooltip>
                    </td>
                }
                <td class="px-2">
                    <Tooltip title="Área total" placement="top">
                        <img
                            className="mx-3"
                            src={process.env.PUBLIC_URL + "/static/react/property/icons/area.png"}
                            alt="Área total"
                        />
                    </Tooltip>
                </td>
                <td class="px-2">
                    <Tooltip title="Área útil" placement="top">
                        <img
                            className="mx-3"
                            src={process.env.PUBLIC_URL + "/static/react/property/icons/areautil.png"}
                            alt="Área útil"
                        />
                    </Tooltip>
                </td>
                {
                    property.bedrooms>0 &&
                    <td class="px-2">
                        <Tooltip title="Quartos" placement="top">
                            <img
                                className="mx-3"
                                src={process.env.PUBLIC_URL + "/static/react/property/icons/bedrooms.png"}
                                alt="Quartos"
                            />
                        </Tooltip>
                    </td>
                }
                {
                    property.bathrooms>0 &&
                    <td class="px-2">
                        <Tooltip title="Casas de banho" placement="top">
                            <img
                                className="mx-3"
                                src={process.env.PUBLIC_URL + "/static/react/property/icons/wc.png"}
                                alt="Casas de banho"
                            />
                        </Tooltip>
                    </td>
                }
            </tr>
            <tr>
                <td></td>
                {
                    property.areaground!==property.area &&
                    <td class="pt-1">
                        <p class="my-auto text-center font-weight-bold">{PropertyService.getArea(property.areaground).join(" ")}</p>
                    </td>
                }
                <td class="pt-1">
                <p class="my-auto text-center font-weight-bold">{PropertyService.getArea(property.area).join(" ")}</p>
                </td>
                <td class="pt-1">
                <p class="my-auto text-center font-weight-bold">{PropertyService.getArea(property.areaconstructed).join(" ")}</p>
                </td>
                {
                    property.bedrooms>0 &&
                    <td class="pt-1">
                        <p class="my-auto text-center font-weight-bold">{property.bedrooms}</p>
                    </td>
                }
                {
                    property.bathrooms>0 &&
                    <td class="pt-1">
                        <p class="my-auto text-center font-weight-bold">{property.bathrooms}</p>
                    </td>
                }
            </tr>
        </table>
    );
}

export default Numbers;