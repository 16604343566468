import React from "react";

import { Grow, Tooltip } from '@material-ui/core';

import LazyImage from "../../Images/LazyImage";
import LazyImageBackground from "../../Images/LazyImageBackground";

import PropertyService from "../../../Services/Property";

import "./index.css";

import priceNotation from "../functions";

const PropertySquare = ({ property, colsNumber, showAgent }) => {

    let divClass = colsNumber === 3 ? "col-12 col-md-6 col-xl-4" : "col-12 col-md-6 col-xxl-4";
    divClass += " mx-0 mb-5 d-flex flex-column propertycard-" + colsNumber;

    return (
        <Grow in={true}>
            <div
                className={divClass}
            >
                <a
                    href={"/propriedades/" + property.id}
                >
                    <LazyImageBackground
                        className="img-cover position-relative"
                        src={
                            process.env.REACT_APP_STATIC + property.images.sort((a, b) => a.order - b.order)[0].image
                        }
                    >
                        <img
                            className="d-block position-absolute b-3 r-3"
                            title={property.energyclass}
                            src={process.env.PUBLIC_URL + "/static/react/property/energyclass/" + property.energyclass.replace(" ", "") + ".png"}
                            alt={"Classe Energética " + property.energyclass.replace(" ", "")}
                        />
                        {
                            PropertyService.getFavorites().indexOf(property.id)>=0 &&
                            <i className="fas fa-heart text-primary position-absolute t-3 r-3 h5"></i>
                        }
                    </LazyImageBackground>
                </a>
                <div className="bg-white p-4 flex-grow-1 d-flex flex-column">
                    <div className="row mx-0 flex-grow-1">
                        <div className="col-9 p-0 mx-0 d-flex flex-column">
                            <p className="text-secondary x-small mb-0">
                                Ref. {property.id}
                            </p>
                            <h6
                                className="font-weight-bold"
                            >
                                {property.title}
                            </h6>
                            <p
                                className="text-secondary mb-2 mt-auto"
                            >
                                {property.parish.county.name}
                            </p>
                            <h5
                                className="font-weight-bold text-primary"
                            >
                                {priceNotation(property.price)}€
                            </h5>
                        </div>
                        {
                            showAgent &&
                            <div className="col-3 p-0 mr-0">
                                <a
                                    href={"/agente/" + property.agent.id}
                                >
                                    <LazyImage
                                        title={property.agent.name + "| Ver página do agente"}
                                        className="mb-auto w-100 round"
                                        src={
                                            property.agent.image
                                                ?
                                                process.env.REACT_APP_STATIC + property.agent.image
                                                :
                                                process.env.PUBLIC_URL + "/static/react/agent/agent.jpg"}
                                    />
                                </a>
                            </div>
                        }
                    </div>
                    <div className="mt-2 mx-auto d-flex flex-wrap justify-content-center">
                        {
                            property.areaground>0 && property.areaground!==property.area 
                            &&
                            <Tooltip title="Área do terreno">
                                <div className="d-flex flex-column">
                                        <img
                                            className="mx-auto px-1"
                                            src={process.env.PUBLIC_URL + "/static/react/property/icons/areaterreno.png"}
                                            alt="Área do terreno"
                                            style={{height: 30}}
                                        />
                                    <p className="mb-auto small text-center font-weight-bold">
                                        {PropertyService.getArea(property.areaground)[0]}
                                        <span className="fw-lighter x-small">{PropertyService.getArea(property.areaground)[1]}</span>
                                    </p>
                                </div>
                            </Tooltip>
                        }
                        <Tooltip title="Área total">
                            <div className="d-flex flex-column">
                                <img
                                    className="mx-auto px-1"
                                    src={process.env.PUBLIC_URL + "/static/react/property/icons/area.png"}
                                    alt="Área total"
                                    style={{height: 30}}
                                />
                                <p className="mb-auto small text-center font-weight-bold">
                                    {PropertyService.getArea(property.area)[0]}
                                    <span className="fw-lighter x-small">{PropertyService.getArea(property.area)[1]}</span>
                                </p>
                            </div>
                        </Tooltip>
                        <Tooltip title="Área útil">
                            <div className="d-flex flex-column">
                                <img
                                    className="mx-auto px-1"
                                    src={process.env.PUBLIC_URL + "/static/react/property/icons/areautil.png"}
                                    alt="Área útil"
                                    style={{height: 30}}
                                />
                                <p className="mb-auto small text-center font-weight-bold">
                                    {PropertyService.getArea(property.areaconstructed)[0]}
                                    <span className="fw-lighter x-small">{PropertyService.getArea(property.areaconstructed)[1]}</span>
                                </p>
                            </div>
                        </Tooltip>
                        {
                            property.bedrooms !== 0 &&
                            <Tooltip title="Quartos">
                                <div className="d-flex flex-column">
                                    <img
                                        className="mx-auto px-1"
                                        src={process.env.PUBLIC_URL + "/static/react/property/icons/bedrooms.png"}
                                        alt="Quartos"
                                        style={{height: 30}}
                                    />
                                    <p className="mb-auto small text-center font-weight-bold">{property.bedrooms}</p>
                                </div>
                            </Tooltip>
                        }
                        {
                            property.bathrooms !== 0 &&
                            <Tooltip title="Casas de banho">
                                <div className="d-flex flex-column">
                                    <img
                                        className="mx-auto px-1"
                                        src={process.env.PUBLIC_URL + "/static/react/property/icons/wc.png"}
                                        alt="Casas de banho"
                                        style={{height: 30}}
                                    />
                                    <p className="mb-auto small text-center font-weight-bold">{property.bathrooms}</p>
                                </div>
                            </Tooltip>
                        }
                        {
                            property.garage &&
                            <Tooltip title="Garagem">
                                <div className="d-flex flex-column">
                                    <img
                                        className="mx-auto px-1"
                                        src={process.env.PUBLIC_URL + "/static/react/property/icons/garage.png"}
                                        alt="Garagem"
                                        style={{height: 30}}
                                    />
                                </div>
                            </Tooltip>
                        }
                    </div>
                </div>
            </div >
        </Grow>
    );
}

export default PropertySquare;